import { FC } from "react";
import { useRoomBookingsQueries } from "../../hooks/RoomBookingsGridPage/useRoomBookingQueries";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import { LocalDateTime } from "@js-joda/core";

interface IActionsSectionProps {
  filters: string;
}

const ActionsSection: FC<IActionsSectionProps> = ({ filters }) => {
  const { getRoomBookingsForExport } = useRoomBookingsQueries();
  const { data, isLoading, error } = getRoomBookingsForExport(
    filters?.split("&$count")[0]
  );

  const exportLogs = () => {
    if (isLoading) {
      console.log("Loading data...");
      return;
    }

    if (error) {
      console.error("Error fetching room bookings:", error);
      return;
    }

    if (data?.value) {
      const entities = data?.value?.entities
        ? data?.value?.entities
            .map((e) =>
              Object.entries(e)
                .filter(
                  ([key]) =>
                    key !== "unixStartsAtWithTimezone" &&
                    key !== "timezone" &&
                    key !== "statusId" &&
                    key !== "workspaceCategoryId" &&
                    key !== "bookingTypeId"
                )
                .map(([key, value]) =>
                  typeof value === "string" ? value.replaceAll(",", " ") : value
                )
                .join(",")
            )
            .join("\n")
        : "";

      const csvContent =
        RoomBookingsGrid.Resources.companyDashboardExportRoomBookingLogsHeader +
        "\n" +
        entities;
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        RoomBookingsGrid.Resources.roomsLogs +
          "_" +
          LocalDateTime.now() +
          RoomBookingsGrid.Resources.csvExtension
      );
      document.body.appendChild(link);

      link.click();
    }
  };

  return (
    <div className="page-actions">
      <div className="btn-big btn-secondary" onClick={exportLogs}>
        <p className="btn-secondary-text">
          {RoomBookingsGrid.Resources.exportRoomsLogsCsv}
        </p>
      </div>
    </div>
  );
};

export default ActionsSection;
