import { ColumnDef } from "@tanstack/react-table";
import { Link } from "react-router-dom";

import { IRoomBookingsItem } from "../../models/RoomBookings/Interfaces/IRoomBookingsItem";
import { ApplicationPathBuilder } from "../../enums/Common/ApplicationPaths";
import AddOrEditRoomBooking from "../../resources/AdminUI/RoomBookings/AddOrEditRoomBooking";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import { firstLetterToLowerCase, String_Format } from "../../utils/utils";
import { formatDateInZone, isSameDay, DATE_FORMAT } from "../../utils/date";
import { RoomBookingTypes } from "../../enums/RoomBookings/RoomBookingTypes";
import RoomBookingTypeList from "../../resources/AdminUI/RoomBookings/RoomBookingTypeList";
import PriceLabel from "../Shared/PriceLabel";
import { SpaceCategories } from "../../enums/Spaces/SpaceCategories";
import SpaceCategoryList from "../../resources/AdminUI/Spaces/SpaceCategoryList";

const buildBookingInfoCell = (row) => {
  const { bookingTypeId, startsAt, endsAt, timezone, bookingAmount } =
    row.original;
  let type = RoomBookingTypes[bookingTypeId];
  let typeName = RoomBookingTypeList.Resources[firstLetterToLowerCase(type)];
  switch (bookingTypeId) {
    case RoomBookingTypes.Hourly:
      return (
        <div className="column-xlarge">
          <p>{typeName}</p>
          <p className="timestamp">
            {formatDateInZone(startsAt, timezone)}-{" "}
            {formatDateInZone(endsAt, timezone)}
            <span className="timezone"> {timezone}</span>
          </p>
        </div>
      );
    case RoomBookingTypes.Daily:
      return (
        <div className="column-xlarge">
          <p>
            {String_Format(
              RoomBookingsGrid.Resources.bookingAmount,
              typeName,
              bookingAmount
            )}
          </p>
          <p className="timestamp">
            {isSameDay(startsAt, endsAt)
              ? formatDateInZone(startsAt, timezone, DATE_FORMAT)
              : formatDateInZone(startsAt, timezone, DATE_FORMAT) +
                " - " +
                formatDateInZone(endsAt, timezone, DATE_FORMAT)}
            <span className="timezone"> {timezone}</span>
          </p>
        </div>
      );
    case RoomBookingTypes.Monthly:
      return (
        <div className="column-xlarge">
          <p>
            {String_Format(
              RoomBookingsGrid.Resources.bookingAmount,
              typeName,
              bookingAmount * 30
            )}
          </p>
          <p className="timestamp">
            {formatDateInZone(startsAt, timezone, DATE_FORMAT)}-{" "}
            {formatDateInZone(endsAt, timezone, DATE_FORMAT)}
            <span className="timezone"> {timezone}</span>
          </p>
        </div>
      );
  }
};

export const roomBookingsColumns: ColumnDef<IRoomBookingsItem>[] = [
  {
    header: "VISIT ID",
    cell: ({ row }) => (
      <div className="column-xsmall">
        <p>{String_Format(RoomBookingsGrid.Resources.id, row.original.id)}</p>
      </div>
    ),
  },
  {
    header: "USER",
    cell: ({ row }) => (
      <div className="column-large">
        <Link
          className="table-item-link"
          to={ApplicationPathBuilder.ViewEmployeeDetails(row.original.userId)}
        >
          {`${row.original.userFirstName} ${row.original.userLastName}`}
          <div>
            {AddOrEditRoomBooking.Resources.userId} #{row.original.userId}
          </div>
        </Link>
      </div>
    ),
  },
  {
    header: "SPACE",
    cell: ({ row }) => (
      <div className="column-large">
        <p>{row.original.workspaceName}</p>
        <p>
          {
            SpaceCategoryList.Resources[
              firstLetterToLowerCase(
                SpaceCategories[row.original.workspaceCategoryId]
              )
            ]
          }{" "}
          | {row.original.workspaceCityName}
        </p>
      </div>
    ),
  },
  {
    header: "ROOM",
    cell: ({ row }) => (
      <div className="column-medium">
        <p className="room-name">{row.original.roomName}</p>
      </div>
    ),
  },
  {
    header: "BOOKING INFO",
    cell: ({ row }) => buildBookingInfoCell(row),
  },
  {
    header: "PRICE",
    cell: ({ row }) => (
      <div className="column-small">
        <PriceLabel
          price={row.original.price}
          currency={row.original.currency}
        />
      </div>
    ),
  },
  {
    header: "STATUS",
    cell: ({ row }) => (
      <div className="column-small">
        <p>{row.original.statusName}</p>
      </div>
    ),
  },
];
