// RoomBookingsGrid.tsx
import React, { FC } from "react";
import { Link } from "react-router-dom";

import { useRoomBookings } from "../../providers/RoomBookingsProvider";
import { useRoomBookingsLogic } from "../../hooks/RoomBookingsGridPage/useRoomBookingLogic";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import { useRoomBookingsQueries } from "../../hooks/RoomBookingsGridPage/useRoomBookingQueries";
import { IRoomBookingsItem } from "../../models/RoomBookings/Interfaces/IRoomBookingsItem";
import PaginationBar from "../Shared/PaginationBar";
import { RoomBookingStatuses } from "../../enums/RoomBookings/RoomBookingStatuses";
import { formatDate } from "../../utils/date";
import Loading from "../Shared/Loading";
import General from "../../resources/AdminUI/General";
import { roomBookingsColumns } from "./RoomBookingsTableColumns";
import { ApplicationPathBuilder } from "../../enums/Common/ApplicationPaths";
import { String_Format } from "../../utils/utils";
import TanstackTable from "../Shared/TanstackTable";

interface IProps {
  userId?: string;
}

const RoomBookingsGridWrapper: FC<IProps> = ({ userId }) => {
  const {
    itemsPerPage,
    numberOfRoomBookings,
    lastIndexFromPage,
    firstIndexFromPage,
    pageIndexArray,
  } = useRoomBookings();

  const { skip, filters, changeCurrentPage, reloadRoomBookingsData } =
    useRoomBookingsLogic(userId);

  const { getRoomBookings } = useRoomBookingsQueries();
  const { data, isLoading } = getRoomBookings(itemsPerPage, skip, filters);

  const expandedContent = (row: IRoomBookingsItem) => (
    <div>
      <div className="span-details-row">
        <Link to={ApplicationPathBuilder.ViewEmployeeDetails(row.userId)}>
          {RoomBookingsGrid.Resources.fullName}{" "}
          <span className="span-row-link">
            {row.userFirstName} {row.userLastName} |{" "}
            {String_Format(RoomBookingsGrid.Resources.id, row.userId)}
          </span>
        </Link>
      </div>

      <div className="span-details-row">
        {RoomBookingsGrid.Resources.emailAddress} <span>{row.userEmail}</span>
      </div>

      <div className="span-details-row">
        {RoomBookingsGrid.Resources.phoneNumber} <span>{row.userPhone}</span>
      </div>

      <div className="span-details-row">
        {row.statusId == RoomBookingStatuses.Cancelled ? (
          <div className="span-details-row">
            {RoomBookingsGrid.Resources.cancelledAt}
            <span className="timestamp">{formatDate(row.cancelledAt)}</span>
          </div>
        ) : null}
        {row.statusId == RoomBookingStatuses.Expired ? (
          <div className="span-details-row">
            {RoomBookingsGrid.Resources.expiredAt}
            <span className="timestamp">{formatDate(row.expiresAt)}</span>
          </div>
        ) : null}
        {row.statusId == RoomBookingStatuses.Rejected ? (
          <div className="span-details-row">
            {RoomBookingsGrid.Resources.rejectedAt}
            <span className="timestamp">{formatDate(row.rejectedAt)}</span>
          </div>
        ) : null}
        <div className="span-details-row">
          {RoomBookingsGrid.Resources.internalNotes}{" "}
          <span>{row.userNotes}</span>
        </div>
      </div>
    </div>
  );

  if (isLoading || !data) {
    return <Loading />;
  }

  return (
    <div>
      {data && data?.entities?.length > 0 && (
        <TanstackTable
          data={data?.entities}
          columns={roomBookingsColumns}
          pageCount={Math.ceil(data.numberOfEntities / itemsPerPage)}
          expandedContent={expandedContent}
        />
      )}
      {data && data?.entities?.length === 0 && (
        <div className="no-elements">{General.Resources.noElements}</div>
      )}
      <PaginationBar
        totalItems={numberOfRoomBookings}
        reload={reloadRoomBookingsData}
        changeCurrentPage={changeCurrentPage}
        firstIndexFromPage={firstIndexFromPage}
        lastIndexFromPage={lastIndexFromPage}
        pageIndexArray={pageIndexArray}
        maxPagesDisplayed={3}
        pageNeighbours={1}
      />
    </div>
  );
};

export default RoomBookingsGridWrapper;
