// RoomBookingsContext.tsx
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useMemo,
} from "react";
import { IRoomBookingsItem } from "../models/RoomBookings/Interfaces/IRoomBookingsItem";
import IPageIndex from "../models/Pagination/IPageIndex";
import { DefaultFilterValues } from "../enums/Common/DefaultFilterValues";

interface RoomBookingsContextType {
  loading: boolean;
  itemsPerPage: number;
  currentPage: number;
  firstIndexFromPage: number;
  lastIndexFromPage: number;
  pageIndexArray: IPageIndex[];
  filters: string;
  roomBookings: IRoomBookingsItem[];
  numberOfRoomBookings: number;
  isRoomBookingHiddenList: boolean[];
  endDateSearchTemplate: number | null;
  startDateSearchTemplate: number | null;
  singleSelectedCity: string;
  singleSelectedCountry: string;
  singleSelectedStatus: string;
  searchTerm: string;
  setLoading: (loading: boolean) => void;
  setItemsPerPage: (itemsPerPage: number) => void;
  setCurrentPage: (currentPage: number) => void;
  setFilters: (filters: string) => void;
  setRoomBookings: (roomBookings: IRoomBookingsItem[]) => void;
  setNumberOfRoomBookings: (numberOfRoomBookings: number) => void;
  setSearchTerm: (searchTerm: string) => void;
  setSingleSelectedStatus: (status: string) => void;
  setSingleSelectedCountry: (country: string) => void;
  setSingleSelectedCity: (city: string) => void;
  setDateRange: (start: number | null, end: number | null) => void;
  setIsRoomBookingHiddenList: (isRoomBookingHiddenList: boolean[]) => void;
  setPageIndexArray: (pageIndexArray: IPageIndex[]) => void;
  setFirstIndexFromPage: (firstIndexFromPage: number) => void;
  setLastIndexFromPage: (lastIndexFromPage: number) => void;
  setStartDateSearchTemplate: (startDateSearchTemplate: number | null) => void;
  setEndDateSearchTemplate: (endDateSearchTemplate: number | null) => void;
}

const RoomBookingsContext = createContext<RoomBookingsContextType | undefined>(
  undefined
);

export const RoomBookingsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [firstIndexFromPage, setFirstIndexFromPage] = useState(1);
  const [lastIndexFromPage, setLastIndexFromPage] = useState(1);
  const [pageIndexArray, setPageIndexArray] = useState<IPageIndex[]>([]);
  const [filters, setFilters] = useState("");
  const [roomBookings, setRoomBookings] = useState<IRoomBookingsItem[]>([]);
  const [numberOfRoomBookings, setNumberOfRoomBookings] = useState(0);
  const [isRoomBookingHiddenList, setIsRoomBookingHiddenList] = useState<
    boolean[]
  >([]);
  const [endDateSearchTemplate, setEndDateSearchTemplate] = useState<
    number | null
  >(null);
  const [startDateSearchTemplate, setStartDateSearchTemplate] = useState<
    number | null
  >(null);
  const [singleSelectedCity, setSingleSelectedCity] = useState(
    DefaultFilterValues.All.toString()
  );
  const [singleSelectedCountry, setSingleSelectedCountry] = useState(
    DefaultFilterValues.All.toString()
  );
  const [singleSelectedStatus, setSingleSelectedStatus] = useState(
    DefaultFilterValues.All.toString()
  );
  const [searchTerm, setSearchTerm] = useState("");

  const setDateRange = (start: number | null, end: number | null) => {
    setStartDateSearchTemplate(start);
    setEndDateSearchTemplate(end);
  };

  const value = useMemo(
    () => ({
      loading,
      itemsPerPage,
      currentPage,
      firstIndexFromPage,
      lastIndexFromPage,
      pageIndexArray,
      filters,
      roomBookings,
      numberOfRoomBookings,
      isRoomBookingHiddenList,
      endDateSearchTemplate,
      startDateSearchTemplate,
      singleSelectedCity,
      singleSelectedCountry,
      singleSelectedStatus,
      searchTerm,
      setLoading,
      setItemsPerPage,
      setCurrentPage,
      setFilters,
      setRoomBookings,
      setNumberOfRoomBookings,
      setSearchTerm,
      setSingleSelectedStatus,
      setSingleSelectedCountry,
      setSingleSelectedCity,
      setDateRange,
      setPageIndexArray,
      setFirstIndexFromPage,
      setLastIndexFromPage,
      setIsRoomBookingHiddenList,
      setStartDateSearchTemplate,
      setEndDateSearchTemplate,
    }),
    [
      loading,
      itemsPerPage,
      currentPage,
      firstIndexFromPage,
      lastIndexFromPage,
      pageIndexArray,
      filters,
      roomBookings,
      numberOfRoomBookings,
      isRoomBookingHiddenList,
      endDateSearchTemplate,
      startDateSearchTemplate,
      singleSelectedCity,
      singleSelectedCountry,
      singleSelectedStatus,
      searchTerm,
      setLoading,
      setItemsPerPage,
      setCurrentPage,
      setFilters,
      setRoomBookings,
      setNumberOfRoomBookings,
      setSearchTerm,
      setSingleSelectedStatus,
      setSingleSelectedCountry,
      setSingleSelectedCity,
      setDateRange,
      setPageIndexArray,
      setFirstIndexFromPage,
      setLastIndexFromPage,
      setIsRoomBookingHiddenList,
      setStartDateSearchTemplate,
      setEndDateSearchTemplate,
    ]
  );

  return (
    <RoomBookingsContext.Provider value={value}>
      {children}
    </RoomBookingsContext.Provider>
  );
};

export const useRoomBookings = () => {
  const context = useContext(RoomBookingsContext);
  if (context === undefined) {
    throw new Error(
      "useRoomBookings must be used within a RoomBookingsProvider"
    );
  }
  return context;
};
