import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { ApplicationState } from "../../../store";
import { TabStore } from "../../../store/TabStore";
import { ITabItem, ITabTitleItem } from "../../../utils/reactUtils";
import HotdeskBookingsTable from "../../HotdeskBookings/HotdeskBookingsTable";
import { BookingLogTypes } from "../../../enums/Common/BookingLogTypes";
import HotdeskBookingsGrid from "../../../resources/AdminUI/HotdeskBookings/HotdeskBookingsGrid";
import RoomBookingsGrid from "../../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import TabsHeader from "../../Tabs/TabsHeader";
import Loading from "../../Shared/Loading";
import RoomBookingsGridWrapper from "../../RoomBookings/RoomBookingsGridWrapper";

const UsersBookingsTablesTabComponent = (props) => {
  const {
    userId,
    hotdeskBookingsTableFilters,
    hotdeskBookingsTableHiddenColumns,
    hotdeskBookingsTableExpandableColumns,
    roomBookingsTableFilters,
    roomBookingsTableHiddenColumns,
    roomBookingsTableExpandableColumns,
    initializeTabPage,
    tabState,
    changeActiveTab,
  } = props;

  useEffect(() => {
    const tabs = [
      {
        tabId: BookingLogTypes.HotdeskLogs.toString(),
        title: HotdeskBookingsGrid.Resources.desksLogs,
        class: "",
        content: () => (
          <HotdeskBookingsTable
            componentId={`user-hotdesk-bookings-${userId}`}
            filters={hotdeskBookingsTableFilters}
            hiddenColumns={hotdeskBookingsTableHiddenColumns}
            expandableColumns={hotdeskBookingsTableExpandableColumns}
          />
        ),
      } as ITabItem,
      {
        tabId: BookingLogTypes.RoomLogs.toString(),
        title: RoomBookingsGrid.Resources.roomLogs,
        class: "",
        content: () => <RoomBookingsGridWrapper userId={userId} />,
      } as ITabItem,
    ];

    initializeTabPage(tabs, BookingLogTypes.HotdeskLogs.toString(), null);
  }, [
    userId,
    hotdeskBookingsTableFilters,
    hotdeskBookingsTableHiddenColumns,
    hotdeskBookingsTableExpandableColumns,
    roomBookingsTableFilters,
    roomBookingsTableHiddenColumns,
    roomBookingsTableExpandableColumns,
    initializeTabPage,
  ]);

  if (!tabState) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const activeTab = tabState.tabs.find((t) => t.tabId === tabState.activeTabId);

  return (
    <div>
      <TabsHeader
        onTabClick={changeActiveTab}
        activeTabId={activeTab.tabId}
        className={"section-table-tabs"}
        tabs={tabState.tabs.map(
          (t) =>
            ({
              title: t.title,
              tabId: t.tabId,
              class: t.class,
            } as ITabTitleItem)
        )}
      />
      <div>{activeTab.content()}</div>
    </div>
  );
};

export default connect(
  (state: ApplicationState, ownProps: any) => ({
    tabState: state.tab[ownProps.componentId],
  }),
  (dispatch, ownProps: any) =>
    bindActionCreators(
      {
        ...TabStore.getActionCreators(ownProps.componentId),
      },
      dispatch
    )
)(UsersBookingsTablesTabComponent);
