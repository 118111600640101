import { useQuery } from "@tanstack/react-query";
import { IEntitiesList, EntitiesList } from "../../models/Common/IEntitiesList";
import { IRoomBookingsItem } from "../../models/RoomBookings/Interfaces/IRoomBookingsItem";
import { RoomBookingsItem } from "../../models/RoomBookings/RoomBookingsItem";
import { useServiceBase } from "../../services/useServiceBase";
import { AppSettings } from "../../utils/settings";

const API_URL = AppSettings.CommonSettings.ApiUrl;

export const useRoomBookingsQueries = () => {
  const { requestJsonFetch } = useServiceBase();

  const additionalMapping = (
    entitiesList: IEntitiesList<IRoomBookingsItem>
  ): EntitiesList<IRoomBookingsItem> => {
    const newEntities = new EntitiesList<IRoomBookingsItem>();
    newEntities.entities = entitiesList.entities.map(
      (e) => new RoomBookingsItem(e)
    );
    newEntities.numberOfEntities = entitiesList.numberOfEntities;
    return newEntities;
  };

  const getRoomBookings = (toTake: number, toSkip: number, filters: string) => {
    return useQuery({
      queryKey: ["roomBookings", toTake, toSkip, filters],
      queryFn: async () => {
        let url = `/odata/room-bookings/`;

        if (filters && filters.length > 0) {
          url = `${url}${filters}`;
        }

        const result = await requestJsonFetch<IEntitiesList<IRoomBookingsItem>>(
          {
            method: "GET",
            url: `${API_URL}${url}`,
          }
        );

        return additionalMapping(result.value);
      },
    });
  };

  const getRoomBookingsForExport = (filters?: string) => {
    return useQuery({
      queryKey: ["roomBookingsExport", filters],
      queryFn: async () => {
        let url = `/odata/room-bookings`;

        if (filters && filters.length > 0) {
          url = `${url}${filters}`;
        }

        const result = await requestJsonFetch<IEntitiesList<IRoomBookingsItem>>(
          {
            method: "GET",
            url: `${API_URL}${url}`,
          }
        );

        return result;
      },
    });
  };

  return { getRoomBookings, getRoomBookingsForExport };
};
