import { FC } from "react";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";

import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";

const { RangePicker } = DatePicker;

export interface IFiltersSectionProps {
  singleSelectedStatus: string;
  searchTerm: string;
  startDateSearchTemplate: any;
  endDateSearchTemplate: any;
  dateFormat: string;
  onSearchFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setSingleSelectedStatus: (value: string) => void;
  onDateChange: (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null]) => void;
  getAllRoomStatuses: () => any[];
}

const FiltersSection: FC<IFiltersSectionProps> = (props) => {
  const {
    searchTerm,
    singleSelectedStatus,
    startDateSearchTemplate,
    endDateSearchTemplate,
    dateFormat,
    onDateChange,
    onSearchFilterChange,
    getAllRoomStatuses,
    setSingleSelectedStatus,
  } = props;
  return (
    <div className="filter-bar">
      <div className="filter-search">
        <div className="input-group">
          <span className="icon material-symbols-outlined">search</span>
          <input
            type="text"
            className="form-control"
            placeholder={
              RoomBookingsGrid.Resources.searchByLogIdUserNameOrSpaceName
            }
            onChange={onSearchFilterChange}
            value={searchTerm}
          />
        </div>
      </div>

      <div className="filter-item">
        <div className="input-group">
          <Select
            options={getAllRoomStatuses()}
            onChange={setSingleSelectedStatus}
            value={singleSelectedStatus}
          ></Select>
        </div>
      </div>

      <RangePicker
        value={[
          startDateSearchTemplate
            ? dayjs.unix(startDateSearchTemplate)
            : startDateSearchTemplate,
          endDateSearchTemplate
            ? dayjs.unix(endDateSearchTemplate)
            : endDateSearchTemplate,
        ]}
        format={dateFormat}
        onChange={onDateChange}
      />
    </div>
  );
};

export default FiltersSection;
