import React from "react";
import Helmet from "react-helmet";
import { Dayjs } from "dayjs";

import { withRouter } from "../../components/Shared/withRouter";
import RoomBookingsGridWrapper from "../../components/RoomBookings/RoomBookingsGridWrapper";
import FiltersSection from "../../components/RoomBookings/FiltersSection";
import { useRoomBookingsLogic } from "../../hooks/RoomBookingsGridPage/useRoomBookingLogic";
import { useRoomBookings } from "../../providers/RoomBookingsProvider";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import ActionsSection from "../../components/RoomBookings/ActionsSection";

const RoomBookingsGridPage = () => {
  const {
    searchTerm,
    singleSelectedStatus,
    startDateSearchTemplate,
    endDateSearchTemplate,
    setSearchTerm,
    setSingleSelectedStatus,
    setDateRange,
  } = useRoomBookings();

  const { filters, getAllRoomStatuses } = useRoomBookingsLogic();

  return (
    <>
      <Helmet title={RoomBookingsGrid.Resources.roomsLogs} />
      <div className="page-top-container">
        <div className="page-title">{RoomBookingsGrid.Resources.roomsLogs}</div>
        <ActionsSection filters={filters} />
      </div>
      <FiltersSection
        dateFormat={"DD-MM-YYYY"}
        singleSelectedStatus={singleSelectedStatus}
        searchTerm={searchTerm}
        startDateSearchTemplate={startDateSearchTemplate}
        endDateSearchTemplate={endDateSearchTemplate}
        onSearchFilterChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
          setSearchTerm(e.target.value)
        }
        setSingleSelectedStatus={setSingleSelectedStatus}
        onDateChange={(dates: [Dayjs | null, Dayjs | null]): void => {
          if (!dates) {
            setDateRange(null, null);
            return;
          }
          setDateRange(dates[0]?.unix(), dates[1]?.unix());
        }}
        getAllRoomStatuses={getAllRoomStatuses}
      />
      <RoomBookingsGridWrapper />
    </>
  );
};

export default withRouter(RoomBookingsGridPage);
